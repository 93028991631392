/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/button-has-type */
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, Button } from '@mui/material';
import './datatable.scss';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import { getbuyorder, admincancelorder, getcoinlist } from '../../api';

function DataTable({ id }) {
    const [data, setData] = useState([]);
    const [symbols, setSymbols] = useState([]);
    const [selectedSymbol, setSelectedSymbol] = useState('RUBY');
    const [page, setPage] = useState(1);
    const [limit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);

    // Fetch symbols for the dropdown
    useEffect(() => {
        const fetchSymbols = async () => {
            try {
                const response = await getcoinlist();
                const formattedSymbols = response.data.map((item) => item.symbol);
                setSymbols(formattedSymbols);
            } catch (error) {
                console.error('Error fetching coin list:', error);
            }
        };

        fetchSymbols();
    }, []);

    // Fetch sell orders based on the selected symbol and pagination
    useEffect(() => {
        const fetchData = async () => {
            try {
                const getsellorders = await getbuyorder(page, limit, selectedSymbol, id);
                const formattedData = getsellorders.data.map((item) => ({
                    id: item._id || item._id.$oid,
                    userId: item.user_id,
                    symbol: item.symbol,
                    orderStatus: item.order_status === 1 ? 'Completed' : 'Pending',
                    createdAt: item.createdAt
                        ? new Date(item.createdAt).toLocaleString('en-US', {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: true,
                          })
                        : 'N/A',
                    orderId: item.order_id,
                    rawPrice: item.raw_price,
                    volume: item.volume,
                    order_status: item.order_status,
                }));
                setData(formattedData);
                setTotalPages(getsellorders.pagination.totalPages);
            } catch (error) {
                console.error('Error fetching sell orders:', error);
            }
        };

        if (selectedSymbol) fetchData();
    }, [page, limit, selectedSymbol, id]);

    const handlePreviousPage = () => {
        if (page > 1) setPage(page - 1);
    };

    const handleNextPage = () => {
        if (page < totalPages) setPage(page + 1);
    };

    const handleCancelOrder = async (orderId) => {
        try {
            // eslint-disable-next-line no-shadow
            const data = {
                orderId,
                ordertype: 'buy',
            };
            const response = await admincancelorder(data);
            console.log('Order cancelled successfully:', response);
            setData((prevData) => prevData.filter((order) => order.orderId !== orderId));
        } catch (error) {
            console.error('Error cancelling order:', error);
        }
    };

    const columns = [
        { field: 'userId', headerName: 'User ID', width: 180 },
        { field: 'symbol', headerName: 'Symbol', width: 100 },
        {
            field: 'orderStatus',
            headerName: 'Status',
            width: 150,
            renderCell: (param) => (
                <div className={`status ${param.row.orderStatus.toLowerCase()}`}>
                    {param.row.orderStatus}
                </div>
            ),
        },
        { field: 'orderId', headerName: 'Order ID', width: 180 },
        { field: 'rawPrice', headerName: 'Raw Price', width: 150 },
        { field: 'volume', headerName: 'Volume', width: 120 },
        { field: 'createdAt', headerName: 'Created At', width: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: (param) =>
                param.row.order_status === 0 ? (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleCancelOrder(param.row.orderId)}
                    >
                        Cancel
                    </Button>
                ) : (
                    <span> - </span>
                ),
        },
    ];

    return (
        <div className="data_table">
            <div className="heading">All Buy Order Data</div>
            <Autocomplete
                options={symbols}
                getOptionLabel={(option) => option}
                value={selectedSymbol}
                onChange={(event, newValue) => {
                    setSelectedSymbol(newValue || '');
                    setPage(1); // Reset page when symbol changes
                }}
                renderInput={(params) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <TextField {...params} label="Search Symbol" variant="outlined" />
                )}
                className="symbol_dropdown"
            />
            <DataGrid
                className="data_grid"
                rows={data}
                columns={columns}
                pageSize={limit}
                rowsPerPageOptions={[10]}
                checkboxSelection
            />
            <div className="pagination_controls">
                <Button
                    onClick={handlePreviousPage}
                    disabled={page === 1}
                    variant="contained"
                    color="primary"
                >
                    Previous
                </Button>
                <span className="page_info">
                    Page {page} of {totalPages}
                </span>
                <Button
                    onClick={handleNextPage}
                    disabled={page === totalPages}
                    variant="contained"
                    color="primary"
                >
                    Next
                </Button>
            </div>
        </div>
    );
}

export default DataTable;
