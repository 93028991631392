/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/button-has-type */
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button'; // Importing Button from Material-UI
import TextField from '@mui/material/TextField'; // For input fields
import Dialog from '@mui/material/Dialog'; // Modal component
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import './datatable.scss';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import { userbalance, addbalancebyadmin } from '../../api';

function Userwithdrawalhistory({ id }) {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [open, setOpen] = useState(false); // For managing the modal open/close state
    const [walletType, setWalletType] = useState(''); // State for wallet type input
    const [userId, setUserId] = useState(''); // State for user ID input
    const [balance, setBalance] = useState(''); // State for balance input
    const [selectedRow, setSelectedRow] = useState(null); // To store selected row data

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch wallet data, which seems to be a wallet history or balance
                const walletData = await userbalance(id);
                console.log('Wallet Data:', walletData.data);

                const walletDetails = walletData.data;
                const formattedData = walletDetails.map((item) => ({
                    id: item._id, // Use _id directly from the response
                    userId: item.user,
                    walletType: item.wallet_type,
                    balance: item.balance,
                    lockedBalance: item.locked,
                    walletAddress: item.wallet_address,
                }));

                setData(formattedData);
                setTotalPages(1); // Set total pages if applicable; assuming no pagination
            } catch (error) {
                console.error('Error fetching wallet data:', error);
            }
        };

        fetchData();
    }, [id]);

    const handlePreviousPage = () => {
        if (page > 1) setPage(page - 1);
    };

    const handleNextPage = () => {
        if (page < totalPages) setPage(page + 1);
    };

    const handleOpenModal = (row) => {
        // Set the selected row's data to be passed to the modal
        setUserId(row.userId);
        setWalletType(row.walletType);
        setSelectedRow(row); // Store the selected row
        setOpen(true); // Open the modal
    };

    const handleCloseModal = () => {
        setOpen(false);
        setBalance(''); // Clear balance input on modal close
    };

    const handleAddBalance = async () => {
        try {
            // Call the function to add balance
            await addbalancebyadmin({
                wallet_type: walletType,
                user: userId,
                balance: parseFloat(balance),
            });
            alert('Balance added successfully!');
            handleCloseModal(); // Close the modal after success
        } catch (error) {
            console.error('Error adding balance:', error);
            alert('Error adding balance!');
        }
    };

    const columns = [
        { field: 'userId', headerName: 'User ID', width: 180 },
        { field: 'walletType', headerName: 'Wallet Type', width: 150 },
        { field: 'walletAddress', headerName: 'Wallet Address', width: 250 },
        { field: 'balance', headerName: 'Balance', width: 150 },
        { field: 'lockedBalance', headerName: 'Locked Balance', width: 180 },
        {
            field: 'action',
            headerName: 'Action',
            width: 180,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenModal(params.row)} // Pass the current row data
                >
                    Add Balance
                </Button>
            ),
        },
    ];

    return (
        <div className="data_table">
            <div className="heading">User Wallet Data</div>
            <DataGrid
                className="data_grid"
                rows={data}
                columns={columns}
                pageSize={10} // Display 10 rows per page
                rowsPerPageOptions={[10]}
                checkboxSelection
            />
            <div className="pagination_controls">
                <Button
                    onClick={handlePreviousPage}
                    disabled={page === 1}
                    variant="contained"
                    color="primary"
                >
                    Previous
                </Button>
                <span className="page_info">
                    Page {page} of {totalPages}
                </span>
                <Button
                    onClick={handleNextPage}
                    disabled={page === totalPages}
                    variant="contained"
                    color="primary"
                >
                    Next
                </Button>
            </div>

            {/* Modal for Adding Balance */}
            <Dialog open={open} onClose={handleCloseModal}>
                <DialogTitle>Add Balance</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Wallet Type"
                        fullWidth
                        value={walletType}
                        disabled
                        style={{ marginBottom: '10px' }}
                    />
                    <TextField
                        label="User ID"
                        fullWidth
                        value={userId}
                        disabled
                        style={{ marginBottom: '10px' }}
                    />
                    <TextField
                        label="Balance"
                        type="number"
                        fullWidth
                        value={balance}
                        onChange={(e) => setBalance(e.target.value)}
                        style={{ marginBottom: '10px' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddBalance} color="primary">
                        Add Balance
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Userwithdrawalhistory;
