import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './datatable.scss';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import { getuser, getuserbyemail } from '../../api';

function DataTable() {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1); // Current page
    const [totalPages, setTotalPages] = useState(1); // Total pages
    const [searchEmail, setSearchEmail] = useState(''); // Search input state
    const [isSearching, setIsSearching] = useState(false); // Flag to check if search is active

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    useEffect(() => {
        if (isSearching) return; // Skip fetching paginated data if searching

        const fetchData = async () => {
            try {
                const response = await getuser(page, 10); // Pass page and limit to the API
                const formattedData = response.data.map((user) => ({
                    // eslint-disable-next-line no-underscore-dangle
                    id: user._id,
                    username: user.user_id,
                    email: user.email,
                    status: user.is_kyc_verified === 1 ? 'success' : 'pending',
                    age: '',
                    isEmailVerified: user.is_email_verified === 1 ? 'Verified' : 'Not Verified',
                    isBankVerified: user.is_bank_verified === 1 ? 'Verified' : 'Not Verified',
                    mobileNumber: user.mobile_number || 'N/A',
                    emailOtp: user.email_otp || 'N/A',
                }));
                setData(formattedData);
                setTotalPages(response.pagination.totalPages); // Set total pages
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchData();
    }, [page, isSearching]);

    const handleSearch = async () => {
        if (!searchEmail.trim()) return; // Do nothing if search input is empty

        setIsSearching(true); // Set searching flag
        try {
            const response = await getuserbyemail(searchEmail); // Call the API with email
            const user = response.data; // Assuming API returns single user object
            const formattedData = [
                {
                    // eslint-disable-next-line no-underscore-dangle
                    id: user._id,
                    username: user.user_id,
                    email: user.email,
                    status: user.is_kyc_verified === 1 ? 'success' : 'pending',
                    age: '',
                    isEmailVerified: user.is_email_verified === 1 ? 'Verified' : 'Not Verified',
                    isBankVerified: user.is_bank_verified === 1 ? 'Verified' : 'Not Verified',
                    mobileNumber: user.mobile_number || 'N/A',
                    emailOtp: user.email_otp || 'N/A',
                },
            ];
            setData(formattedData);
            setTotalPages(1); // Only one page for search result
        } catch (error) {
            console.error('Error fetching user by email:', error);
            setData([]); // Clear the table if no user found
        }
    };

    const handleClearSearch = () => {
        setSearchEmail(''); // Clear search input
        setIsSearching(false); // Reset searching flag
        setPage(1); // Reset pagination
    };

    const columns = [
        { field: 'username', headerName: 'Username', width: 180 },
        { field: 'email', headerName: 'Email', width: 280 },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (param) => (
                <div className={`status ${param.row.status}`}>{param.row.status}</div>
            ),
        },
        {
            field: 'isEmailVerified',
            headerName: 'Email Verified',
            width: 150,
            renderCell: (param) => (
                <div
                    className={`verification-status ${
                        param.row.isEmailVerified === 'Verified' ? 'verified' : 'not-verified'
                    }`}
                >
                    {param.row.isEmailVerified}
                </div>
            ),
        },
        {
            field: 'isBankVerified',
            headerName: 'Bank Verified',
            width: 150,
            renderCell: (param) => (
                <div
                    className={`verification-status ${
                        param.row.isBankVerified === 'Verified' ? 'verified' : 'not-verified'
                    }`}
                >
                    {param.row.isBankVerified}
                </div>
            ),
        },
        { field: 'mobileNumber', headerName: 'Mobile Number', width: 150 },
        { field: 'emailOtp', headerName: 'Email OTP', width: 120 },
        {
            field: 'action',
            headerName: 'Action',
            width: 170,
            renderCell: (params) => (
                <div className="actionn">
                    <Link to={`/Detail/${params.row.id}`}>
                        <button type="button" className="view_btn">
                            View
                        </button>
                    </Link>

                    <button
                        type="button"
                        className="delete_btn"
                        // You can implement delete functionality if needed
                    >
                        Delete
                    </button>
                </div>
            ),
        },
    ];

    return (
        <div className="home">
            <div className="home_sidebar">
                <Sidebar />
            </div>
            <div className="orders_main">
                <Navbar />
                <div className="data_table">
                    <div className="heading">All User Data</div>
                    <div className="search-bar">
                        <input
                            type="text"
                            placeholder="Search by email"
                            value={searchEmail}
                            onChange={(e) => setSearchEmail(e.target.value)}
                        />
                        <button type="button" onClick={handleSearch}>
                            Search
                        </button>
                        <button type="button" onClick={handleClearSearch}>
                            Clear
                        </button>
                    </div>
                    <DataGrid
                        className="data_grid"
                        rows={data}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        checkboxSelection
                        pagination
                        paginationMode="server" // Enable server-side pagination
                        page={page - 1} // Zero-based index for page
                        onPageChange={(newPage) => handlePageChange(newPage + 1)} // Adjust for zero-based index
                        rowCount={totalPages * 10} // Total number of rows available
                    />
                </div>
            </div>
        </div>
    );
}

export default DataTable;
