import React, { useEffect, useState } from 'react';
import '../DataTable/orders.scss';

// MUI components
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { getcoinlist, updateTradingStatus, updateDepositandWithdrawalStatus } from '../../api';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';

function Coinsetting() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false); // State for dialog
    const [confirmAction, setConfirmAction] = useState(null); // Function to call after confirmation

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getcoinlist();
                const coinData = response.data.map((item) => ({
                    symbol: item.symbol,
                    priceInr: item.inr_price,
                    isTrade: item.is_trade,
                    isDeposit: item.is_deposite,
                    isWithdrawal: item.is_withdrawal,
                    status: item.coin_status ? 'Active' : 'Inactive',
                    createdAt: new Date(item.createdAt).toLocaleDateString('en-US'),
                }));
                setData(coinData);
            } catch (error) {
                console.error('Error fetching coin list:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleConfirm = (action) => {
        setConfirmAction(() => action);
        setOpen(true);
    };

    const handleDialogClose = (confirm) => {
        setOpen(false);
        if (confirm && confirmAction) {
            confirmAction();
        }
    };

    const handleToggleTradingStatus = (symbol) => {
        handleConfirm(async () => {
            try {
                const currentCoin = data.find((item) => item.symbol === symbol);
                const updatedStatus = currentCoin.isTrade === 1 ? 0 : 1;

                const updatedData = data.map((item) =>
                    item.symbol === symbol ? { ...item, isTrade: updatedStatus } : item
                );
                setData(updatedData);

                await updateTradingStatus(symbol, { is_trade: updatedStatus });
            } catch (error) {
                console.error('Error updating trading status:', error);
            }
        });
    };

    const handleToggleDepositStatus = (symbol, status) => {
        handleConfirm(async () => {
            try {
                const updatedStatus = status === 1 ? 0 : 1;
                await updateDepositandWithdrawalStatus(
                    symbol,
                    { is_deposite: updatedStatus },
                    'is_deposite'
                );
                const updatedData = data.map((item) =>
                    item.symbol === symbol ? { ...item, isDeposit: updatedStatus } : item
                );
                setData(updatedData);
            } catch (error) {
                console.error('Error updating deposit status:', error);
            }
        });
    };

    const handleToggleWithdrawalStatus = (symbol, status) => {
        handleConfirm(async () => {
            try {
                const updatedStatus = status === 1 ? 0 : 1;
                await updateDepositandWithdrawalStatus(
                    symbol,
                    { is_withdrawal: updatedStatus },
                    'is_withdrawal'
                );
                const updatedData = data.map((item) =>
                    item.symbol === symbol ? { ...item, isWithdrawal: updatedStatus } : item
                );
                setData(updatedData);
            } catch (error) {
                console.error('Error updating withdrawal status:', error);
            }
        });
    };

    return (
        <div className="orders">
            <div className="home_sidebar">
                <Sidebar />
            </div>
            <div className="orders_main">
                <Navbar />
                <div>
                    <div className="heading">Coin List</div>

                    <TableContainer component={Paper} className="table_list">
                        {loading ? (
                            <div className="loader-container">
                                <CircularProgress size={60} color="primary" />
                            </div>
                        ) : (
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="table_cell">Symbol</TableCell>
                                        <TableCell className="table_cell">Price (INR)</TableCell>
                                        <TableCell className="table_cell">Trading Status</TableCell>
                                        <TableCell className="table_cell">Deposit Status</TableCell>
                                        <TableCell className="table_cell">
                                            Withdrawal Status
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((row) => (
                                        <TableRow key={row.symbol}>
                                            <TableCell className="table_cell">
                                                {row.symbol}
                                            </TableCell>
                                            <TableCell className="table_cell">
                                                {row.priceInr}
                                            </TableCell>
                                            <TableCell className="table_cell">
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    color={row.isTrade === 1 ? 'success' : 'error'}
                                                    onClick={() =>
                                                        handleToggleTradingStatus(row.symbol)
                                                    }
                                                >
                                                    {row.isTrade === 1 ? 'Enabled' : 'Disabled'}
                                                </Button>
                                            </TableCell>
                                            <TableCell className="table_cell">
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    color={
                                                        row.isDeposit === 1 ? 'success' : 'error'
                                                    }
                                                    onClick={() =>
                                                        handleToggleDepositStatus(
                                                            row.symbol,
                                                            row.isDeposit
                                                        )
                                                    }
                                                >
                                                    {row.isDeposit === 1
                                                        ? 'Deposit Enabled'
                                                        : 'Deposit Disabled'}
                                                </Button>
                                            </TableCell>
                                            <TableCell className="table_cell">
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    color={
                                                        row.isWithdrawal === 1 ? 'success' : 'error'
                                                    }
                                                    onClick={() =>
                                                        handleToggleWithdrawalStatus(
                                                            row.symbol,
                                                            row.isWithdrawal
                                                        )
                                                    }
                                                >
                                                    {row.isWithdrawal === 1
                                                        ? 'Withdrawal Enabled'
                                                        : 'Withdrawal Disabled'}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        )}
                    </TableContainer>
                </div>
            </div>

            {/* Confirmation Dialog */}
            <Dialog
                open={open}
                onClose={() => handleDialogClose(false)}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="confirm-dialog-title">Confirm Action</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        Are you sure you want to perform this action?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDialogClose(false)} color="error">
                        Cancel
                    </Button>
                    <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Coinsetting;
