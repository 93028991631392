/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/button-has-type */
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button'; // Importing Button from Material-UI
import './datatable.scss';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import { getuserdata } from '../../api';

function Userwithdrawalhistory({ id }) {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [limit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const withdrawalHistory = await getuserdata(id, page, limit);
                console.log('withdrawalHistory', withdrawalHistory.deposite_historys);
                const formattedData = withdrawalHistory?.deposite_historys?.map((item) => ({
                    id: item._id?.$oid || item._id,
                    userId: item.user_id,
                    email: item.email,
                    symbol: item.symbol,
                    status: item.status ? 'Completed' : 'Pending',
                    amount: item.amount,
                    withdrawalFee: item.withdrawal_fee,
                    fromAddress: item.from_address,
                    toAddress: item.to_address,
                    transactionId: item.transection_id,
                    createdAt: item.createdAt
                        ? new Date(item.createdAt).toLocaleString('en-US', {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: true,
                          })
                        : 'N/A',
                }));
                setData(formattedData);
                setTotalPages(withdrawalHistory?.pagination?.totalPages || 1);
            } catch (error) {
                console.error('Error fetching withdrawal history:', error);
            }
        };

        fetchData();
    }, [id, page, limit]);

    const handlePreviousPage = () => {
        if (page > 1) setPage(page - 1);
    };

    const handleNextPage = () => {
        if (page < totalPages) setPage(page + 1);
    };

    const columns = [
        { field: 'userId', headerName: 'User ID', width: 180 },
        { field: 'email', headerName: 'Email', width: 280 },
        { field: 'symbol', headerName: 'Symbol', width: 100 },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (param) => (
                <div className={`status ${param.row.status}`}>{param.row.status}</div>
            ),
        },
        { field: 'amount', headerName: 'Amount', width: 120 },
        { field: 'withdrawalFee', headerName: 'Withdrawal Fee', width: 150 },
        { field: 'fromAddress', headerName: 'From Address', width: 200 },
        { field: 'toAddress', headerName: 'To Address', width: 200 },
        { field: 'transactionId', headerName: 'Transaction ID', width: 200 },
        { field: 'createdAt', headerName: 'Created At', width: 150 },
    ];

    return (
        <div className="data_table">
            <div className="heading">All deposit History Data</div>
            <DataGrid
                className="data_grid"
                rows={data}
                columns={columns}
                pageSize={limit}
                rowsPerPageOptions={[10]}
                checkboxSelection
            />
            <div className="pagination_controls">
                <Button
                    onClick={handlePreviousPage}
                    disabled={page === 1}
                    variant="contained"
                    color="primary"
                >
                    Previous
                </Button>
                <span className="page_info">
                    Page {page} of {totalPages}
                </span>
                <Button
                    onClick={handleNextPage}
                    disabled={page === totalPages}
                    variant="contained"
                    color="primary"
                >
                    Next
                </Button>
            </div>
        </div>
    );
}

export default Userwithdrawalhistory;
