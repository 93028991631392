/* eslint-disable radix */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Adduserbalance from '../../Components/DataTable/adduserbalance';
import Getuserbuyhistory from '../../Components/DataTable/getuserbuyhistory';
import Getusersellorder from '../../Components/DataTable/getusersellorder';
import Userdeposithistory from '../../Components/DataTable/userdeposit_history';
import Userwithdrawalhistory from '../../Components/DataTable/userwithdrawal_history';

import Navbar from '../../Components/Navbar/Navbar';
import Sidebar from '../../Components/Sidebar/Sidebar';
import './Detail.css';

const token = localStorage.getItem('token'); // Assuming you're storing the token in localStorage

function TabButton({ label, activeTab, onClick }) {
    return (
        <button
            type="button"
            className={`profile-button ${activeTab === label ? 'active' : ''}`}
            onClick={() => onClick(label)}
        >
            {label.replace(/([A-Z])/g, ' $1').trim()} {/* Add spacing for camelCase */}
        </button>
    );
}

function UserProfile() {
    const { id } = useParams();
    const [user, setUser] = useState(null);
    const [userdata, setUserdata] = useState(null);
    const [activeTab, setActiveTab] = useState('profile'); // Default tab set to 'profile'
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(
                    `https://api.digipocket.io/admin/getuserinfo/${id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
                        },
                    }
                );
                setUser(response.data.user);
                setUserdata(response.data.userdata);
                setLoading(false);
                // eslint-disable-next-line no-shadow
            } catch (error) {
                setError('Error fetching user data');
                setLoading(false);
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, [id]);

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    if (error) {
        return <div className="error">{error}</div>; // Display error message if data fetching fails
    }

    const handleTabClick = (tab) => {
        setActiveTab(tab); // Set the active tab when a button is clicked
    };

    return (
        <div className="orders">
            <div className="home_sidebar">
                <Sidebar />
            </div>
            <div className="orders_main">
                <Navbar />
                <div>
                    <div className="heading">User Profile</div>
                </div>
                <div className="button-group">
                    {/* Reusable TabButton component */}
                    {[
                        'profile',
                        'buyOrder',
                        'sellOrder',
                        'balance',
                        'depositHistory',
                        'withdrawalHistory',
                    ].map((tab) => (
                        <TabButton
                            key={tab}
                            label={tab}
                            activeTab={activeTab}
                            onClick={handleTabClick}
                        />
                    ))}
                </div>

                <div className="user-profile">
                    {/* Conditionally render the Profile content */}
                    {activeTab === 'profile' && (
                        <div className="user-info">
                            <h3>Basic Info</h3>
                            <p>
                                <strong>Name:</strong> {user.name}
                            </p>
                            <p>
                                <strong>Email:</strong> {user.email}
                            </p>
                            <p>
                                <strong>Mobile Number:</strong> {user.mobile_number}
                            </p>
                            <p>
                                <strong>Created On:</strong>{' '}
                                {new Date(user.created_on).toLocaleDateString()}
                            </p>
                            <p>
                                <strong>Referral Code:</strong> {user.self_ref_code}
                            </p>
                            <p>
                                <strong>User Role:</strong>{' '}
                                {user.user_role === 0 ? 'User' : 'Admin'}
                            </p>
                            <p>
                                <strong>Status:</strong>{' '}
                                {user.user_status === 1 ? 'Active' : 'Inactive'}
                            </p>
                        </div>
                    )}

                    {activeTab === 'withdrawalHistory' && <Userwithdrawalhistory id={id} />}
                    {activeTab === 'buyOrder' && <Getuserbuyhistory id={id} />}
                    {activeTab === 'sellOrder' && <Getusersellorder id={id} />}
                    {activeTab === 'balance' && <Adduserbalance id={id} />}
                    {activeTab === 'depositHistory' && <Userdeposithistory id={id} />}
                </div>
            </div>
        </div>
    );
}

export default UserProfile;
