/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import { adminlogin } from '../../api';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const navigate = useNavigate(); // useNavigate inside the component

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const data = {
                password,
                email,
            };
            const response = await adminlogin(data);

            if (response.error) {
                setError(response.message); // Display error message
            } else {
                // Store the token
                localStorage.setItem('token', response.params.token);

                // Redirect to the home page
                navigate('/'); // Adjust the path as per your routing setup
            }
        } catch (err) {
            setError(err.message || 'An unexpected error occurred');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container">
            <h2>Login Page</h2>
            <form onSubmit={handleSubmit} className="form">
                <div className="input-group">
                    <label htmlFor="email" className="label">
                        Email:
                    </label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="input"
                        placeholder="Enter your email"
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="password" className="label">
                        Password:
                    </label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="input"
                        placeholder="Enter your password"
                    />
                </div>
                {/* Display error message */}
                {error && <div className="error">{error}</div>}
                <button type="submit" className="button" disabled={loading}>
                    {loading ? 'Logging in...' : 'Login'}
                </button>
            </form>
        </div>
    );
}

export default Login;
