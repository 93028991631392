import React from 'react';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ children }) {
    const token = localStorage.getItem('token'); // Retrieve token from localStorage

    if (!token) {
        // If no token, redirect to the login page
        return <Navigate to="/Login" replace />;
    }

    // If authenticated, render the child components
    return children;
}

export default ProtectedRoute;
