/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/button-has-type */
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import './datatable.scss';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import { getuserbalance } from '../../api';

function DataTable() {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [limit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const withdrawalHistory = await getuserbalance(page, limit);
                const formattedData = withdrawalHistory.data.map((item) => ({
                    id: item._id?.$oid || item._id,
                    email: item.email,
                    walletAddress: item.wallet_address,
                    walletType: item.wallet_type,
                    balance: item.balance,
                    locked: item.locked,
                    createdAt: item.createdAt
                        ? new Date(item.createdAt).toLocaleString('en-US', {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: true,
                          })
                        : 'N/A',
                }));
                setData(formattedData);
                setTotalPages(withdrawalHistory.pagination.totalPages);
            } catch (error) {
                console.error('Error fetching withdrawal history:', error);
            }
        };

        fetchData();
    }, [page, limit]);

    const handlePreviousPage = () => {
        if (page > 1) setPage(page - 1);
    };

    const handleNextPage = () => {
        if (page < totalPages) setPage(page + 1);
    };

    const columns = [
        { field: 'email', headerName: 'Email ID', width: 200 },
        { field: 'walletAddress', headerName: 'Wallet Address', width: 350 },
        { field: 'walletType', headerName: 'Wallet Type', width: 80 },
        { field: 'balance', headerName: 'Balance', width: 100 },
        { field: 'locked', headerName: 'Locked', width: 100 },
        { field: 'createdAt', headerName: 'Created At', width: 200 },
    ];

    return (
        <div className="home">
            <div className="home_sidebar">
                <Sidebar />
            </div>
            <div className="orders_main">
                <Navbar />
                <div className="data_table">
                    <div className="heading">All user Balances Data</div>
                    <DataGrid
                        className="data_grid"
                        rows={data}
                        columns={columns}
                        pageSize={limit}
                        rowsPerPageOptions={[10]}
                        checkboxSelection
                    />
                    <div className="pagination_controls">
                        <Button
                            onClick={handlePreviousPage}
                            disabled={page === 1}
                            variant="contained"
                            color="primary"
                        >
                            Previous
                        </Button>
                        <span className="page_info">
                            Page {page} of {totalPages}
                        </span>
                        <Button
                            onClick={handleNextPage}
                            disabled={page === totalPages}
                            variant="contained"
                            color="primary"
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DataTable;
