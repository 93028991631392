import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import './datatable.scss';
import { Select, MenuItem, InputLabel, FormControl, TextField } from '@mui/material';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import { getWithdrawalhistory, getcoinlist } from '../../api';

function DataTable() {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [limit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [symbol, setSymbol] = useState('RUBY');
    const [coinList, setCoinList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const withdrawalHistory = await getWithdrawalhistory(page, limit, symbol);
                const formattedData = withdrawalHistory.data.map((item) => ({
                    // eslint-disable-next-line no-underscore-dangle
                    id: item._id?.$oid || item._id,
                    userId: item.user_id,
                    email: item.email,
                    symbol: item.symbol,
                    status: item.status ? 'Completed' : 'Pending',
                    amount: item.amount,
                    withdrawalFee: item.withdrawal_fee,
                    fromAddress: item.from_address,
                    toAddress: item.to_address,
                    transactionId: item.transection_id,
                    createdAt: item.createdAt
                        ? new Date(item.createdAt).toLocaleString('en-US', {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: true,
                          })
                        : 'N/A',
                }));
                setData(formattedData);
                setTotalPages(withdrawalHistory.pagination.totalPages);
            } catch (error) {
                console.error('Error fetching withdrawal history:', error);
            }
        };

        fetchData();
    }, [symbol, page, limit]);

    useEffect(() => {
        const fetchCoinList = async () => {
            try {
                const coins = await getcoinlist();
                if (Array.isArray(coins.data)) {
                    setCoinList(coins.data);
                } else {
                    console.error('Expected an array from getcoinlist, but got:', coins);
                }
            } catch (error) {
                console.error('Error fetching coin list:', error);
            }
        };

        fetchCoinList();
    }, []);

    const handleSymbolChange = (event) => {
        setSymbol(event.target.value);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    // Filter coins based on the search term (only within the dropdown)
    const filteredCoins = coinList.filter((coin) =>
        coin.symbol.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handlePreviousPage = () => {
        if (page > 1) setPage(page - 1);
    };

    const handleNextPage = () => {
        if (page < totalPages) setPage(page + 1);
    };

    const columns = [
        { field: 'userId', headerName: 'User ID', width: 180 },
        { field: 'email', headerName: 'Email', width: 280 },
        { field: 'symbol', headerName: 'Symbol', width: 100 },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (param) => (
                <div className={`status ${param.row.status}`}>{param.row.status}</div>
            ),
        },
        { field: 'amount', headerName: 'Amount', width: 120 },
        { field: 'withdrawalFee', headerName: 'Withdrawal Fee', width: 150 },
        { field: 'fromAddress', headerName: 'From Address', width: 200 },
        { field: 'toAddress', headerName: 'To Address', width: 200 },
        { field: 'transactionId', headerName: 'Transaction ID', width: 200 },
        { field: 'createdAt', headerName: 'Created At', width: 150 },
    ];

    return (
        <div className="home">
            <div className="home_sidebar">
                <Sidebar />
            </div>
            <div className="orders_main">
                <Navbar />
                <div className="data_table">
                    <div className="heading">All Withdrawal Data</div>

                    {/* Coin Dropdown and Search */}
                    <div className="coin-dropdown">
                        {/* Search Bar for Filtering Coins */}

                        {/* Coin Selection Dropdown */}
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="symbol-select-label">Symbol</InputLabel>
                            <Select
                                labelId="symbol-select-label"
                                value={symbol}
                                onChange={handleSymbolChange}
                                label="Symbol"
                            >
                                {filteredCoins.length > 0 ? (
                                    filteredCoins.map((coin) => (
                                        <MenuItem key={coin.symbol} value={coin.symbol}>
                                            {coin.symbol}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem disabled>No coins found</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </div>

                    <DataGrid
                        className="data_grid"
                        rows={data}
                        columns={columns}
                        pageSize={limit}
                        rowsPerPageOptions={[10]}
                        checkboxSelection
                    />

                    <div className="pagination_controls">
                        <Button
                            onClick={handlePreviousPage}
                            disabled={page === 1}
                            variant="contained"
                            color="primary"
                        >
                            Previous
                        </Button>
                        <span className="page_info">
                            Page {page} of {totalPages}
                        </span>
                        <Button
                            onClick={handleNextPage}
                            disabled={page === totalPages}
                            variant="contained"
                            color="primary"
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DataTable;
