import React, { useEffect, useState } from 'react';
import './orders.scss';

// MUI components
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Autocomplete, TextField } from '@mui/material';
import { getdeposithistory, getcoinlist } from '../../api';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';

function TableList() {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [limit] = useState(10); // Number of items per page
    const [totalPages, setTotalPages] = useState(1);
    const [copiedTransactionId, setCopiedTransactionId] = useState(null);
    const [coinList, setCoinList] = useState([]);
    const [selectedSymbol, setSelectedSymbol] = useState('RUBY'); // Default to RUBY

    useEffect(() => {
        // Fetch deposit history data on component mount or page change
        const fetchData = async () => {
            try {
                const symbolToFetch = selectedSymbol || 'RUBY'; // Default to 'RUBY' if no symbol selected
                const depositHistory = await getdeposithistory(page, limit, symbolToFetch);

                // Format the data
                const formattedData = depositHistory.data.map((item) => ({
                    // eslint-disable-next-line no-underscore-dangle
                    id: item._id?.$oid || item._id,
                    email: item.email,
                    txId: item.tx_id,
                    symbol: item.symbol,
                    status: item.status ? 'Completed' : 'Pending',
                    amount: item.amount,
                    fromAddress: item.from_address,
                    transactionId: item.transection_id,
                    captureStatus: item.capture_status ? 'Captured' : 'Not Captured',
                    createdAt: new Date(item.createdAt).toLocaleDateString('en-US'), // Parse the ISO string
                }));
                setData(formattedData);
                setTotalPages(depositHistory.pagination.totalPages); // Set the total pages for pagination
            } catch (error) {
                console.error('Error fetching deposit history:', error);
            }
        };

        fetchData();
    }, [page, limit, selectedSymbol]);

    // Fetch coin list when component mounts
    useEffect(() => {
        const fetchCoinList = async () => {
            try {
                const coins = await getcoinlist();
                setCoinList(coins.data); // Assuming getcoinlist returns an array of coins
            } catch (error) {
                console.error('Error fetching coin list:', error);
            }
        };

        fetchCoinList();
    }, []);

    const handlePreviousPage = () => {
        if (page > 1) setPage(page - 1);
    };

    const handleNextPage = () => {
        if (page < totalPages) setPage(page + 1);
    };

    const handleCopy = (transactionId) => {
        navigator.clipboard.writeText(transactionId);
        setCopiedTransactionId(transactionId);
        setTimeout(() => setCopiedTransactionId(null), 2000); // Reset copied state after 2 seconds
    };

    return (
        <div className="orders">
            <div className="home_sidebar">
                <Sidebar />
            </div>
            <div className="orders_main">
                <Navbar />

                <div>
                    <div className="heading">Deposit History</div>

                    {/* Coin selection dropdown */}
                    <Autocomplete
                        options={coinList}
                        getOptionLabel={(option) => option.symbol}
                        value={
                            coinList.find((coin) => coin.symbol === selectedSymbol) || {
                                symbol: 'Undefined',
                            }
                        }
                        onChange={(event, newValue) =>
                            setSelectedSymbol(newValue?.symbol || 'RUBY')
                        } // Set selectedSymbol
                        renderInput={(params) => (
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            <TextField {...params} label="Select Symbol" variant="outlined" />
                        )}
                    />

                    <TableContainer component={Paper} className="table_list">
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="table_cell">Transaction ID</TableCell>
                                    <TableCell className="table_cell">Symbol</TableCell>
                                    <TableCell className="table_cell">Email</TableCell>
                                    <TableCell className="table_cell">Amount</TableCell>
                                    <TableCell className="table_cell">Date</TableCell>
                                    <TableCell className="table_cell">Payment Status</TableCell>
                                    <TableCell className="table_cell">Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            className="table_cell"
                                        >
                                            <div className="product_idd">
                                                {row.transactionId.slice(-20)}
                                                <IconButton
                                                    onClick={() => handleCopy(row.transactionId)}
                                                    size="small"
                                                    color="primary"
                                                    aria-label="copy transaction ID"
                                                    style={{ marginLeft: '8px' }}
                                                >
                                                    <ContentCopyIcon fontSize="small" />
                                                </IconButton>
                                                {copiedTransactionId === row.transactionId && (
                                                    <span
                                                        style={{
                                                            marginLeft: '8px',
                                                            color: 'green',
                                                            fontSize: '0.8rem',
                                                        }}
                                                    >
                                                        Copied!
                                                    </span>
                                                )}
                                            </div>
                                        </TableCell>
                                        <TableCell className="table_cell">{row.symbol}</TableCell>
                                        <TableCell className="table_cell">{row.email}</TableCell>
                                        <TableCell className="table_cell">{row.amount}</TableCell>
                                        <TableCell className="table_cell">
                                            {row.createdAt}
                                        </TableCell>
                                        <TableCell className="table_cell">
                                            {row.captureStatus}
                                        </TableCell>
                                        <TableCell className="table_cell">
                                            <span
                                                className={`status ${
                                                    row.status === 'Completed'
                                                        ? 'completed'
                                                        : 'pending'
                                                }`}
                                            >
                                                {row.status}
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* Pagination Controls */}
                    <div className="pagination_controls">
                        <Button
                            onClick={handlePreviousPage}
                            disabled={page === 1}
                            variant="contained"
                            color="primary"
                        >
                            Previous
                        </Button>
                        <span className="page_info">
                            Page {page} of {totalPages}
                        </span>
                        <Button
                            onClick={handleNextPage}
                            disabled={page === totalPages}
                            variant="contained"
                            color="primary"
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TableList;
