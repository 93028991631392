import React, { useEffect, useState } from 'react';
import '../DataTable/orders.scss';

// MUI components
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress'; // Importing loader component
import TextField from '@mui/material/TextField'; // Importing TextField for editable cells
import { getcoinlist, updateCoinData } from '../../api'; // API functions for fetching and updating data
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';

function Coinsetting() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); // Initialize loading state

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getcoinlist(); // Fetch data from the API
                const coinData = response.data.map((item) => ({
                    symbol: item.symbol,
                    min_deposite_limit: item.min_deposite_limit,
                    min_withdraw_limit: item.min_withdraw_limit,
                    max_withdraw_limit: item.max_withdraw_limit,
                    withdrawal_fee: item.withdrawal_fee,
                    deposit_fee: item.deposit_fee,
                    order_low_limit: item.order_low_limit || 0, // Add new fields
                    order_high_limit: item.order_high_limit || 0, // Add new fields
                }));
                setData(coinData);
            } catch (error) {
                console.error('Error fetching coin list:', error);
            } finally {
                setLoading(false); // Set loading to false after the data is fetched
            }
        };

        fetchData();
    }, []);

    const handleCellChange = (value, field, symbol) => {
        const updatedData = data.map((coin) => {
            if (coin.symbol === symbol) {
                return { ...coin, [field]: value }; // Update only the field that was changed
            }
            return coin;
        });
        setData(updatedData);
    };

    const handleUpdate = async (row) => {
        // Show a confirmation dialog to the admin
        const isConfirmed = window.confirm(
            `Are you sure you want to update the data for ${row.symbol}?`
        );
        if (!isConfirmed) {
            // If admin clicks "Cancel", do not proceed
            return;
        }
        try {
            await updateCoinData(row); // Call the API function to update the data
            alert('Coin data updated successfully!'); // Optionally show a success message
        } catch (error) {
            console.error('Error updating coin data:', error);
            alert('Failed to update coin data!');
        }
    };

    return (
        <div className="orders">
            <div className="home_sidebar">
                <Sidebar />
            </div>
            <div className="orders_main">
                <Navbar />
                <div>
                    <div className="heading">Coin List</div>

                    <TableContainer component={Paper} className="table_list">
                        {loading ? (
                            <div className="loader-container">
                                <CircularProgress size={60} color="primary" />
                            </div>
                        ) : (
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            className="table_cell"
                                            style={{
                                                position: 'sticky',
                                                top: 0,
                                                backgroundColor: '#fff',
                                                zIndex: 1,
                                            }}
                                        >
                                            Symbol
                                        </TableCell>
                                        <TableCell
                                            className="table_cell"
                                            style={{
                                                position: 'sticky',
                                                top: 0,
                                                backgroundColor: '#fff',
                                                zIndex: 1,
                                            }}
                                        >
                                            Min Deposit Limit
                                        </TableCell>
                                        <TableCell
                                            className="table_cell"
                                            style={{
                                                position: 'sticky',
                                                top: 0,
                                                backgroundColor: '#fff',
                                                zIndex: 1,
                                            }}
                                        >
                                            Min Withdraw Limit
                                        </TableCell>
                                        <TableCell
                                            className="table_cell"
                                            style={{
                                                position: 'sticky',
                                                top: 0,
                                                backgroundColor: '#fff',
                                                zIndex: 1,
                                            }}
                                        >
                                            Max Withdraw Limit
                                        </TableCell>
                                        <TableCell
                                            className="table_cell"
                                            style={{
                                                position: 'sticky',
                                                top: 0,
                                                backgroundColor: '#fff',
                                                zIndex: 1,
                                            }}
                                        >
                                            Withdrawal Fee
                                        </TableCell>
                                        <TableCell
                                            className="table_cell"
                                            style={{
                                                position: 'sticky',
                                                top: 0,
                                                backgroundColor: '#fff',
                                                zIndex: 1,
                                            }}
                                        >
                                            Deposit Fee
                                        </TableCell>
                                        <TableCell
                                            className="table_cell"
                                            style={{
                                                position: 'sticky',
                                                top: 0,
                                                backgroundColor: '#fff',
                                                zIndex: 1,
                                            }}
                                        >
                                            Order Low Limit %
                                        </TableCell>
                                        <TableCell
                                            className="table_cell"
                                            style={{
                                                position: 'sticky',
                                                top: 0,
                                                backgroundColor: '#fff',
                                                zIndex: 1,
                                            }}
                                        >
                                            Order High Limit %
                                        </TableCell>
                                        <TableCell
                                            className="table_cell"
                                            style={{
                                                position: 'sticky',
                                                top: 0,
                                                backgroundColor: '#fff',
                                                zIndex: 1,
                                            }}
                                        >
                                            Actions
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((row) => (
                                        <TableRow key={row.symbol}>
                                            <TableCell className="table_cell">
                                                {row.symbol}
                                            </TableCell>
                                            <TableCell className="table_cell">
                                                <TextField
                                                    value={row.min_deposite_limit}
                                                    onChange={(e) =>
                                                        handleCellChange(
                                                            e.target.value,
                                                            'min_deposite_limit',
                                                            row.symbol
                                                        )
                                                    }
                                                    size="small"
                                                    fullWidth
                                                />
                                            </TableCell>
                                            <TableCell className="table_cell">
                                                <TextField
                                                    value={row.min_withdraw_limit}
                                                    onChange={(e) =>
                                                        handleCellChange(
                                                            e.target.value,
                                                            'min_withdraw_limit',
                                                            row.symbol
                                                        )
                                                    }
                                                    size="small"
                                                    fullWidth
                                                />
                                            </TableCell>
                                            <TableCell className="table_cell">
                                                <TextField
                                                    value={row.max_withdraw_limit}
                                                    onChange={(e) =>
                                                        handleCellChange(
                                                            e.target.value,
                                                            'max_withdraw_limit',
                                                            row.symbol
                                                        )
                                                    }
                                                    size="small"
                                                    fullWidth
                                                />
                                            </TableCell>
                                            <TableCell className="table_cell">
                                                <TextField
                                                    value={row.withdrawal_fee}
                                                    onChange={(e) =>
                                                        handleCellChange(
                                                            e.target.value,
                                                            'withdrawal_fee',
                                                            row.symbol
                                                        )
                                                    }
                                                    size="small"
                                                    fullWidth
                                                />
                                            </TableCell>
                                            <TableCell className="table_cell">
                                                <TextField
                                                    value={row.deposit_fee}
                                                    onChange={(e) =>
                                                        handleCellChange(
                                                            e.target.value,
                                                            'deposit_fee',
                                                            row.symbol
                                                        )
                                                    }
                                                    size="small"
                                                    fullWidth
                                                />
                                            </TableCell>
                                            <TableCell className="table_cell">
                                                <TextField
                                                    value={row.order_low_limit}
                                                    onChange={(e) =>
                                                        handleCellChange(
                                                            e.target.value,
                                                            'order_low_limit',
                                                            row.symbol
                                                        )
                                                    }
                                                    size="small"
                                                    fullWidth
                                                />
                                            </TableCell>
                                            <TableCell className="table_cell">
                                                <TextField
                                                    value={row.order_high_limit}
                                                    onChange={(e) =>
                                                        handleCellChange(
                                                            e.target.value,
                                                            'order_high_limit',
                                                            row.symbol
                                                        )
                                                    }
                                                    size="small"
                                                    fullWidth
                                                />
                                            </TableCell>
                                            <TableCell className="table_cell">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleUpdate(row)}
                                                >
                                                    Update
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        )}
                    </TableContainer>
                </div>
            </div>
        </div>
    );
}

export default Coinsetting;
