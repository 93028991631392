import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { ColorContext } from './ColorContext/darkContext';

import Home from './Components/Home/Home';
import DepositHistory from './Components/DataTable/DepositHistory';
import Users from './Components/DataTable/Users';
import Pendingwithdrawal from './Components/DataTable/pending_withdrawal';
import Withdrawalhistory from './Components/DataTable/withdrawal_history';
import Sellhistory from './Components/DataTable/sellhistory';
import Buyhistory from './Components/DataTable/buyhistory';
import Userbalance from './Components/DataTable/userbalance';
import Detail from './Pages/Detail/detail';
import Login from './Pages/Login/Login';
import Coinsetting from './Components/Setting/coinsetting';
import Setcoinlimit from './Components/Setting/Setcoinlimit';
import ProtectedRoute from './Pages/Login/ProtectedRoute';

import './app.scss';

function App() {
    const { darkMode } = useContext(ColorContext);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    // Check if token exists in localStorage to determine login state
    useEffect(() => {
        if (localStorage.getItem('token')) {
            setIsAuthenticated(true);
        }
    }, []);

    return (
        <div className={darkMode ? 'App dark' : 'App'}>
            <BrowserRouter>
                <Routes>
                    {/* Public Routes */}
                    <Route
                        path="/Login"
                        element={<Login setIsAuthenticated={setIsAuthenticated} />}
                    />
                    {/* Redirect based on token */}
                    <Route
                        path="/"
                        element={isAuthenticated ? <Home /> : <Navigate to="/Login" />}
                    />
                    {/* Protected Routes */}
                    <Route
                        path="/Home"
                        element={
                            <ProtectedRoute>
                                <Home />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/Users"
                        element={
                            <ProtectedRoute>
                                <Users />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/Pendingwithdrawal"
                        element={
                            <ProtectedRoute>
                                <Pendingwithdrawal />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/DepositHistory"
                        element={
                            <ProtectedRoute>
                                <DepositHistory />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/Sellhistory"
                        element={
                            <ProtectedRoute>
                                <Sellhistory />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/Buyhistory"
                        element={
                            <ProtectedRoute>
                                <Buyhistory />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/Withdrawalhistory"
                        element={
                            <ProtectedRoute>
                                <Withdrawalhistory />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/Userbalance"
                        element={
                            <ProtectedRoute>
                                <Userbalance />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/Coinsetting"
                        element={
                            <ProtectedRoute>
                                <Coinsetting />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/Setcoinlimit"
                        element={
                            <ProtectedRoute>
                                <Setcoinlimit />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/Detail/:id"
                        element={
                            <ProtectedRoute>
                                <Detail />
                            </ProtectedRoute>
                        }
                    />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
