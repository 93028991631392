/* eslint-disable import/prefer-default-export */
// api.js
import axios from 'axios';

const API_URL = 'https://api.digipocket.io/admin/';
const token = localStorage.getItem('token'); // Assuming you're storing the token in localStorage

export const getuser = async (page = 1, limit = 10) => {
    try {
        const response = await axios.get(`${API_URL}getalluser`, {
            params: { page, limit }, // Pass page and limit as query parameters
            headers: {
                Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
        });
        return response.data; // Return the actual data to simplify component usage
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
};

// eslint-disable-next-line default-param-last
export const getdeposithistory = async (page = 1, limit = 10, symbol) => {
    try {
        const response = await axios.get(`${API_URL}getdeposithistory`, {
            params: { page, limit, symbol }, // Pass page and limit as query parameters
            headers: {
                Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
        });
        return response.data; // Return the actual data to simplify component usage
    } catch (error) {
        console.error('Error fetching deposit history data:', error);
        throw error;
    }
};

// eslint-disable-next-line default-param-last
export const getWithdrawalhistory = async (page = 1, limit = 10, symbol) => {
    try {
        const response = await axios.get(`${API_URL}getWithdrawalhistory`, {
            params: { page, limit, symbol }, // Pass page and limit as query parameters
            headers: {
                Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
        });
        return response.data; // Return the actual data to simplify component usage
    } catch (error) {
        console.error('Error fetching deposit history data:', error);
        throw error;
    }
};

// eslint-disable-next-line default-param-last
export const getsellorder = async (page = 1, limit = 10, baseCurrency) => {
    try {
        const response = await axios.get(`${API_URL}getsellorder`, {
            params: { page, limit, baseCurrency }, // Pass page and limit as query parameters
            headers: {
                Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
        });
        return response.data; // Return the actual data to simplify component usage
    } catch (error) {
        console.error('Error fetching deposit history data:', error);
        throw error;
    }
};
// eslint-disable-next-line default-param-last
export const getbuyorder = async (page = 1, limit = 10, baseCurrency) => {
    try {
        const response = await axios.get(`${API_URL}getbuyorder`, {
            params: { page, limit, baseCurrency }, // Pass page and limit as query parameters
            headers: {
                Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
        });
        return response.data; // Return the actual data to simplify component usage
    } catch (error) {
        console.error('Error fetching deposit history data:', error);
        throw error;
    }
};
// eslint-disable-next-line default-param-last
export const getpendingwithdrawal = async (page = 1, limit = 10) => {
    try {
        const response = await axios.get(`${API_URL}getpendingwithdrawal`, {
            params: { page, limit }, // Pass page and limit as query parameters
            headers: {
                Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
        });
        return response.data; // Return the actual data to simplify component usage
    } catch (error) {
        console.error('Error fetching deposit history data:', error);
        throw error;
    }
};

export const getuserbalance = async (page = 1, limit = 10) => {
    try {
        const response = await axios.get(`${API_URL}getuserbalance`, {
            params: { page, limit }, // Pass page and limit as query parameters
            headers: {
                Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
        });
        return response.data; // Return the actual data to simplify component usage
    } catch (error) {
        console.error('Error fetching deposit history data:', error);
        throw error;
    }
};

export const getuserdata = async (id, page = 1, limit = 10) => {
    try {
        const response = await axios.get(`${API_URL}getuserdata/${id}`, {
            params: { page, limit }, // Pass page and limit as query parameters
            headers: {
                Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
        });
        return response.data; // Return the actual data to simplify component usage
    } catch (error) {
        console.error('Error fetching deposit history data:', error);
        throw error;
    }
};

export const admincancelorder = async (data) => {
    try {
        const response = await axios.post('https://api.digipocket.io/api/admincancelorder', data);
        return response.data;
    } catch (error) {
        console.error('Error cancelling the order:', error.message);
        throw error; // Re-throw error for the caller to handle
    }
};
export const getcoinlist = async () => {
    try {
        const response = await axios.get(`${API_URL}getcoinlist`, {
            headers: {
                Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
        });
        return response.data; // Return the actual data to simplify component usage
    } catch (error) {
        console.error('Error fetching coin list:', error);
        throw error; // Re-throw error for the caller to handle
    }
};

export const updateTradingStatus = async (symbol, data) => {
    console.log('id, data', symbol, data.is_trade);
    try {
        const payload = {
            symbol,
            is_trade: data.is_trade,
        };
        const response = await axios.post(`${API_URL}updateTradingStatus`, payload, {
            headers: {
                Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
        });
        return response.data; // Return the actual data to simplify component usage
    } catch (error) {
        console.error('Error fetching deposit history data:', error);
        throw error;
    }
};

export const updateDepositandWithdrawalStatus = async (symbol, data, statusType) => {
    console.log('id, data, statusType', symbol, data, statusType);
    try {
        // Dynamically set the payload based on the statusType (either deposit or withdrawal)
        const payload = {
            symbol,
            [statusType]: data[statusType], // Dynamically add either 'is_deposit' or 'is_withdrawal' based on statusType.
            type: statusType,
        };

        // Perform the API request to update the deposit/withdrawal status
        const response = await axios.post(`${API_URL}updateDepositandWithdrawalStatus`, payload, {
            headers: {
                Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
        });

        return response.data; // Return the actual data to simplify component usage
    } catch (error) {
        console.error('Error updating deposit or withdrawal status:', error);
        throw error;
    }
};

export const updateCoinData = async (row) => {
    try {
        const response = await axios.post(`${API_URL}updatecoinsetting`, row, {
            headers: {
                Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
        });
        return response.data; // Return the actual data to simplify component usage
    } catch (error) {
        console.error('Error fetching deposit history data:', error);
        throw error;
    }
};

export const getuserbyemail = async (email) => {
    try {
        const response = await axios.get(
            `${API_URL}getuserbyemail/${email}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
                },
            }
        );
        return response.data; // Return the actual data to simplify component usage
    } catch (error) {
        console.error('Error fetching   data:', error);
        throw error;
    }
};

// eslint-disable-next-line default-param-last
export const getuserbuyorder = async (page = 1, limit = 10, baseCurrency, id) => {
    try {
        const response = await axios.get(`${API_URL}getuserbuyorder`, {
            params: { page, limit, baseCurrency, id }, // Pass page and limit as query parameters
            headers: {
                Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
        });
        return response.data; // Return the actual data to simplify component usage
    } catch (error) {
        console.error('Error fetching deposit history data:', error);
        throw error;
    }
};

// eslint-disable-next-line default-param-last
export const getusersellorder = async (page = 1, limit = 10, baseCurrency, id) => {
    try {
        const response = await axios.get(`${API_URL}getusersellorder`, {
            params: { page, limit, baseCurrency, id }, // Pass page and limit as query parameters
            headers: {
                Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
        });
        return response.data; // Return the actual data to simplify component usage
    } catch (error) {
        console.error('Error fetching deposit history data:', error);
        throw error;
    }
};

export const userbalance = async (id) => {
    try {
        const response = await axios.get(`${API_URL}userbalance`, {
            params: { id }, // Pass page and limit as query parameters

            headers: {
                Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
        });
        return response.data; // Return the actual data to simplify component usage
    } catch (error) {
        console.error('Error fetching deposit history data:', error);
        throw error;
    }
};

export const addbalancebyadmin = async (row) => {
    try {
        const response = await axios.post(`${API_URL}addbalancebyadmin`, row, {
            headers: {
                Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
        });
        return response.data; // Return the actual data to simplify component usage
    } catch (error) {
        console.error('Error fetching deposit data:', error);
        throw error;
    }
};

export const adminlogin = async (data) => {
    console.log('data', data);
    try {
        const response = await axios.post(`${API_URL}adminlogin`, data);
        return response.data; // Return the actual data to simplify component usage
    } catch (error) {
        console.error('Error fetching deposit data:', error);
        throw error;
    }
};
