/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BarChartIcon from '@mui/icons-material/BarChart';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ColorContext } from '../../ColorContext/darkContext';
import './Sidebar.scss';

function Sidebar() {
    // color state management using react context
    const { darkMode, dispatch } = useContext(ColorContext);
    const navigate = useNavigate();

    const handleLogout = () => {
        // Clear authentication token from localStorage
        localStorage.removeItem('token');

        // Optionally clear other user-related data or state

        // Navigate to the login page
        navigate('/Login');
    };
    return (
        <div className={`sidebar ${darkMode ? 'dark' : ''}`}>
            <div className="logo">
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <h3 className="text_none">AdminDashboard</h3>
                </Link>
            </div>

            <div className="links">
                <ul>
                    <p className="spann">Main</p>
                    <Link to="/" style={{ textDecoration: 'none' }}>
                        <li>
                            <DashboardIcon className="icon" /> Dashboard
                        </li>
                    </Link>
                    <p className="spann">Lists</p>
                    <Link to="/Users" style={{ textDecoration: 'none' }}>
                        <li>
                            <PersonIcon className="icon" /> Users
                        </li>
                    </Link>
                    <Link to="/Pendingwithdrawal" style={{ textDecoration: 'none' }}>
                        <li>
                            <CreditCardIcon className="icon" /> Pending Withdrawal
                        </li>
                    </Link>
                    <Link to="/DepositHistory" style={{ textDecoration: 'none' }}>
                        <li>
                            <CreditCardIcon className="icon" /> Deposit History
                        </li>
                    </Link>
                    <Link to="/Withdrawalhistory" style={{ textDecoration: 'none' }}>
                        <li>
                            <CreditCardIcon className="icon" /> Withdrawal History
                        </li>
                    </Link>
                    <Link to="/userbalance" style={{ textDecoration: 'none' }}>
                        <li>
                            <CreditCardIcon className="icon" /> User Balance
                        </li>
                    </Link>
                    <Link to="/sellhistory" style={{ textDecoration: 'none' }}>
                        <li>
                            <CreditCardIcon className="icon" /> Sell History
                        </li>
                    </Link>
                    <Link to="/buyhistory" style={{ textDecoration: 'none' }}>
                        <li>
                            <CreditCardIcon className="icon" /> Buy History
                        </li>
                    </Link>
                    <li>
                        <BarChartIcon className="icon" /> Status
                    </li>
                    <p className="spann">Settings</p>
                    <Link to="/Coinsetting" style={{ textDecoration: 'none' }}>
                        <li>
                            <CreditCardIcon className="icon" /> Coin Setting
                        </li>
                    </Link>
                    <Link to="/Setcoinlimit" style={{ textDecoration: 'none' }}>
                        <li>
                            <CreditCardIcon className="icon" /> Set coin limit
                        </li>
                    </Link>
                    <li>
                        <SettingsRoundedIcon className="icon" /> Setting
                    </li>
                    <Link to="/Login" style={{ textDecoration: 'none' }}>
                        <li>
                            <LogoutIcon className="icon" /> Login
                        </li>
                    </Link>
                    <li onClick={handleLogout} style={{ cursor: 'pointer' }}>
                        <LogoutIcon className="icon" /> Log Out
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Sidebar;
